import { ColumnLayout, Container, Grid } from '@amzn/awsui-components-react-v3';
import { FieldError, SectionHeader } from '../form/Fields';
import { Poll, PollSchedule } from '../../data-types';
import { PollScheduleForm, formToModel, modelToForm } from '../form/PollScheduleForm';
import React, { useState } from 'react';

import SelectDateTime from '../form/SelectDateTime';
import { Suite } from 'vest';
import _ from 'lodash';

export interface PollScheduleTabProps {
    poll: Poll;
    onPollScheduleChanged: (schedule: PollSchedule) => void;
    validator: Suite<(input: { schedule: PollScheduleForm }) => void>;
}

const PollScheduling: React.FC<PollScheduleTabProps> = ({
    poll,
    onPollScheduleChanged,
    validator,
}: PollScheduleTabProps) => {
    const createScheduleData = (poll: Poll): PollScheduleForm => {
        const pollSchedule: PollSchedule = {
            startTime: poll.eligibility?.schedule?.start_date,
            endTime: poll.eligibility?.schedule?.expiration_date,
        };
        const scheduleData = modelToForm(pollSchedule);
        validator({ schedule: scheduleData });
        return scheduleData;
    };
    const [scheduleData, setScheduleData] = useState(createScheduleData(poll) as PollScheduleForm);

    const getFieldError = (field: string): string => {
        const fieldError = validator.get().getErrors(field);
        return _.first(fieldError) ?? '';
    };

    const onStartChange = (startDate: string, startTime: string) => {
        const updatePollSchedule: PollScheduleForm = { ...scheduleData, startTime, startDate };
        setScheduleData(updatePollSchedule);
        const schedule: PollSchedule = formToModel(updatePollSchedule);
        onPollScheduleChanged(schedule);
    };

    const onEndChange = (endDate: string, endTime: string) => {
        const updatePollSchedule: PollScheduleForm = { ...scheduleData, endTime, endDate };
        setScheduleData(updatePollSchedule);
        const schedule: PollSchedule = formToModel(updatePollSchedule);
        onPollScheduleChanged(schedule);
    };

    // Return JSX representing your component
    return (
        <div>
            <Grid gridDefinition={[{ colspan: 12 }, { colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                <Container header={<SectionHeader header="Eligible Dates" />}>
                    <FieldError error={getFieldError('startAndEnd')} />
                    <ColumnLayout columns={2}>
                        <Container>
                            <SelectDateTime
                                enabled={true}
                                date={scheduleData.startDate}
                                time={scheduleData.startTime}
                                dateError={getFieldError('startDate')}
                                timeError={getFieldError('startTime')}
                                onChange={(startDate, startTime) => onStartChange(startDate, startTime)}
                            />
                        </Container>
                        <Container>
                            <SelectDateTime
                                enabled={true}
                                date={scheduleData.endDate}
                                time={scheduleData.endTime}
                                dateError={getFieldError('endDate')}
                                timeError={getFieldError('endTime')}
                                onChange={(endDate, endTime) => onEndChange(endDate, endTime)}
                            />
                        </Container>
                    </ColumnLayout>
                </Container>
            </Grid>
        </div>
    );
};

export default PollScheduling;
